import Cuisines from '../apis/cuisine'
import Banner from '../apis/banner'
import Vendor from '../apis/vendor'

const state = {
  cuisines: [],
  restaurantDetails: {},
  filters: {
    sort: '',
    cuisine_id: [],
  },
  restaurantTimingList: [],
  restaurantRatingList: [],
  restaurantDetailsBySlug: {},
  restaurantList: [],
  brandListState: [],
  bannerListState: [],
  businessCategoryListState: [],
}
const actions = {
  getCuisines({ commit }, data) {
    return Cuisines.getCuisineList(data).then((response) => {
      if (response.data.code == 200) {
        commit('getCuisines', response.data.Result)
      }
      return response.data
    })
  },

  getRestaurants({ commit }, data) {
    return Vendor.getVendorListing(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getRestaurantDetails({ commit }, data) {
    return Vendor.getVendorDetails(data).then((response) => {
      if (response.data.code == 200) {
        commit('getRestaurantDetails', response.data.Result)
      }
      return response.data
    })
  },

  getBanners({ commit }, data) {
    return Banner.getBanner(data).then((response) => {
      if (response.data.code == 200) {
        // return response.data;
        commit('bannerListMutation', response.data.Result)
      }
      return response.data
    })
  },

  updateFilterSort({ commit }, data) {
    commit('updateFilterSort', data)
  },

  updateFilterCuisines({ commit }, data) {
    commit('updateFilterCuisines', data)
  },

  getVendorOnOffStatus({ commit }, data) {
    return Vendor.getVendorOnOffStatus(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
        //commit('getVendorOnOffStatus', response.data.Result)
      }
      return response.data
    })
  },

  checkVendorRadius({ commit }, data) {
    return Vendor.checkVendorRadius(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  checkVendorMenuWithPrice({ commit }, data) {
    return Vendor.checkVendorMenuWithPrice(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getRestaurantTimingData({ commit }, data) {
    return Vendor.getRestaurantTiming(data).then((response) => {
      if (response.data.code == 200) {
        commit('restaurantTimingList', response.data.Result)
      }
      return response.data
    })
  },

  getRestaurantRating({ commit }, data) {
    return Vendor.getRestaurantRating(data).then((response) => {
      if (response.data.code == 200) {
        commit('restaurantRatingList', response.data.Result)
      }
      return response.data
    })
  },

  getRestaurantBanner({ commit }, data) {
    return Vendor.getBannerRestaurant(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getSearchRestaurant({ commit }, data) {
    return Vendor.searchRestaurant(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getSearchRestaurantItem({ commit }, data) {
    return Vendor.searchRestaurantItems(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getRestaurantBySlug({ commit }, data) {
    return Vendor.getRestaurantDetailsBySlug(data).then((response) => {
      if (response.data.code == 200) {
        commit('getRestaurantBySlug', response.data.Result)
      }
      return response.data
    })
  },

  getBusinessCategoryList({ commit }, data) {
    return Vendor.getRestaurantCategorylisting(data).then((response) => {
      if (response.data.code == 200) {
        // return response.data;
        commit('getBusinessCateMutation', response.data.Result)
      }
      return response.data
    })
  },

  getRestaurantMenuCategoryList({ commit }, data) {
    return Vendor.getRestaurantMenuCategory(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getItemSoldStatusList({ commit }, data) {
    return Vendor.getItemsSoldStatus(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getMenuWiseItem({ commit }, data) {
    return Vendor.MenuWiseItems(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getCategoryWiseMenu({ commit }, data) {
    return Vendor.getRestaurantMenuWiseCategory(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getSearchRestaurantCategoryItem({ commit }, data) {
    return Vendor.searchRestaurantCategoryItem(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getBannerCategoryData({ commit }, data) {
    return Banner.getBannerCategory(data).then((response) => {
      if (response.data.code == 200) {
        // return response.data;
        commit('bannerListMutation', response.data.Result)
      }
      return response.data
    })
  },

  getCurrentLocationRestaurant({ commit }, data) {
    return Vendor.currentLocationRestaurant(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getBrandListData({ commit }, data) {
    return Vendor.getBrandList(data).then((response) => {
      if (response.data.code == 200) {
        return response.data;
        // commit('getBrandListMutation', response.data.Result)
      }
      return response.data
    })
  },

  getBrandRestaurants({ commit }, data) {
    return Vendor.getBrandRestaurantList(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },

  getDunzoQuotationData({ commit }, data) {
    return Vendor.getDunzoQuotation(data).then((response) => {
      if (response.data.code == 200) {
        return response.data
      }
      return response.data
    })
  },
}
const mutations = {
  getCuisines(state, cuisines) {
    state.cuisines = [...cuisines]
  },
  getRestaurantDetails(state, restaurantDetails) {
    state.restaurantDetails = restaurantDetails
  },
  updateFilterSort(state, data) {
    state.filters.sort = data
  },
  updateFilterCuisines(state, data) {
    state.filters.cuisine_id = data
  },
  restaurantTimingList(state, data) {
    state.restaurantTimingList = data
  },
  restaurantRatingList(state, data) {
    state.restaurantRatingList = data
  },
  getRestaurantBySlug(state, data) {
    state.restaurantDetailsBySlug = data
  },
  updateSortCuisines(state, data) {
    state.filters.sort = data.sort
    state.filters.cuisine_id = data.cusinesIds
  },
  getRestaurantListMutation(state, data) {
    state.restaurantList = data
  },
  getBrandListMutation(state, data) {
    state.brandListState = data
  },
  bannerListMutation(state, data) {
    state.bannerListState = data
  },
  getBusinessCateMutation(state, data) {
    state.businessCategoryListState = data
  },
}
const getters = {
  cuisines: (state) => state.cuisines,
  restaurantDetails: (state) => state.restaurantDetails,
  filters: (state) => state.filters,
  restaurantTimingList: (state) => state.restaurantTimingList,
  restaurantRatingList: (state) => state.restaurantRatingList,
  restaurantDetailsBySlug: (state) => state.restaurantDetailsBySlug,
  getRestaurantListData: (state) => state.restaurantList,
  brandListState: (state) => state.brandListState,
  bannerListState: (state) => state.bannerListState,
  businessCategoryListState: (state) => state.businessCategoryListState,
}

export const restaurant = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
