import Vue from 'vue'
import Vuex from 'vuex'

import i18n, { selectedLocale } from '../i18n'
Vue.use(Vuex)

const state = {
    locale: selectedLocale,
    updatedLang: false
};
const actions = {
    changeLangLocale({ commit }, newLocale) {
        i18n.locale = newLocale // important!
        commit('updateLocale', newLocale)
    }
};
const mutations = {
    updateLocale(state, newLocale) {
        state.locale = newLocale
        state.updatedLang = true
    }
};
const getters = {
    lang: state => state.locale,
    updatedLang: state => state.updatedLang
}

export const lang = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};