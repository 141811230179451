import router from "../router"
import moment from 'moment';
import Vue from "vue";

const debounce = (func, delay) => {
    let debounceTimer
    return function () {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => func.apply(context, args), delay)
    }
}

const set_axios_defaults = (token) => {
    window.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    window.axios.defaults.headers.common["Accept"] = "application/json";
    window.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    window.axios.interceptors.response.use(
        res => res,
        debounce(err => {
            if (err.response.status === 401) {
                if (window.axios.defaults.headers.common.Authorization !== 'Bearer null') {
                    Vue.toasted.error('Session Expired. Try login again', {
                        duration: 2000
                    });
                }
                remove_token()
                Vue.prototype.$auth.user = null;
                router.push({ name: 'sign-in' }).catch(() => { })
            } else if (err.response.status === 500) {
                Vue.toasted.error('Server error', {
                    duration: 2000
                });
                // throw new Error(`${err.config.url} not found`);
            } else if (err.response.status === 422) {
                var message = Object.values(err.response.data.errors)[0][0]
                Vue.toasted.error(message, {
                    duration: 2000
                });
                //throw new Error(`${err.config.url} not found`);
            }
            throw err;
        }, 500)
    );
}
const get_user = () => {
    return Vue.prototype.$auth.user;
    // return JSON.parse(localStorage.getItem('user'));
}
const set_token = (token) => localStorage.setItem('token', token)
const get_token = () => localStorage.getItem('token')
const set_defualt_driver_tip = (tip) => localStorage.setItem('driverTip', tip)
const remove_token = () => localStorage.removeItem('token');
const generate_csv = (data, name) => {
    var blob = new Blob([data], { "type": "csv/plain" });
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = name
    link.click()
}
const date_ranges = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0)
    return {
        'Today': [moment().startOf('day').toDate(), moment().startOf('day').toDate()],
        'Yesterday': [moment().subtract(1, 'day').startOf('day').toDate(), moment().subtract(1, 'day').startOf('day').toDate()],
        'Last 7 days': [moment().subtract(1, 'week').startOf('day').toDate(), moment().startOf('day').toDate()],
        'Last 30 Days': [moment().subtract(30, 'days').startOf('day').toDate(), moment().startOf('day').toDate()],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
    }
}

const getLanguageText = (code) => {
    if (code == 'en') {
        return 'English';
    } else if (code == 'ar') {
        return 'عربى';
    } else if (code == 'es') {
        return 'Española';
    } else if (code == 'th') {
        return 'ไทย';
    } else if (code == 'de') {
        return 'Deutsch';
    } else if (code == 'ru') {
        return 'русский';
    } else if (code == 'fr') {
        return 'French';
    } else {
        return 'English';
    }
}

export {
    debounce,
    set_axios_defaults,
    get_user,
    generate_csv,
    set_token,
    get_token,
    remove_token,
    date_ranges,
    getLanguageText,
    set_defualt_driver_tip
};