const baseUrl = window.location.protocol + '//' + window.location.hostname + window.location.pathname
const image_baseUrl = 'https://storage.googleapis.com/deonde-prod/'
// const image_baseUrl = 'https://www.gerechtaanhuis.nl/admin/public/';
//https://www.gerechtaanhuis.nl/admin/public/media/foodapp/original/food_logo_16975262471107.png
//const image_baseUrl = 'https://admin.liefertuete.de/public/';

let host = window.location.hostname;
// sta setup url
let apiUrl = 'https://api.sta.ug/api/v2/'
let socketUrl = 'https://dashboard.sta.ug:8000';
let loadSharAPI = "https://api.loadshare.net/hyperlocal/v2/";


if (host == 'localhost' || host == '192.168.0.15') {
    apiUrl = 'https://api.sta.ug/api/v2/'
    socketUrl = 'https://dashboard.sta.ug:8000';
    loadSharAPI = "https://api-staging.loadshare.net/hyperlocal/v2/";
}

export default {
    appurl: baseUrl,
    coupon: image_baseUrl + 'media/Coupon/',
    applogo: image_baseUrl + 'media/foodapp/original/',
    signInLogo: image_baseUrl + 'media/LoginSigupImage/',
    google_key: window.google_map_key,
    api_url: apiUrl,
    socket_url: socketUrl,
    loadSharAPI: loadSharAPI,
}
