export default {
    CheckMobileNumber: (data) => window.axios.post('check_user_details', data),
    CheckUserDetails: (data) => window.axios.post('mobile-number-get-user-details', data),
    CheckLogin: (data) => window.axios.post('login', data),
    getUserDetails: (data) => window.axios.post('get_user_details_web', data),
    getVendorSettings: (data) => window.axios.post('get-vendor-settings', data),
    customerChangePassword: (data) => window.axios.post('customer_change_password', data),
    loginGooglefacebook: (data) => window.axios.post('facebook_login', data),
    getLanguageSettings: (data) => window.axios.get('get-language?order_web=' + data),
    postSendMessage: (data) => window.axios.post('sendmessage', data),
    getMessageList: (data) => window.axios.post('getmessage', data),
    customerForgotPassword: (data) => window.axios.post('customer_forgot_password', data),
    forgotPasswordVerifyOtp: (data) => window.axios.post('verify_otp', data),
}