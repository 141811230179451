import Login from '../apis/login';
import Profile from '../apis/profile';
import Logout from '../apis/logout';
import SignUp from '../apis/signup';
import Wallet from '../apis/wallet';

const state = {
    userWallet: 0,
    walletAmount: 0
};
const actions = {
    checkMobileNumber({ commit }) {
        return Login.CheckMobileNumber({
            vendor_id: this.$auth.getVendorId(),
            user_role: '1',
            mobile_number: '',
            is_langauge: this.$store.state.lang.locale,
        }).then(response => {
            commit('checkMobileNumber', response.data);
        })
    },

    CheckUserDetails({ commit }, data) {
        return Login.CheckUserDetails(data).then(response => {
            if (response.data.code == 200) {
                window.axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.user_details.token}`;
                return response.data;
            }
            return response.data;
        })
    },

    checkLogin({ commit }, data) {
        return Login.CheckLogin(data).then(response => {
            if (response.data.code == 200) {
                window.axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.user_details.token}`;
                return response.data;
            }
            return response.data;
        })
    },

    checkLogout({ commit }, data) {
        return Logout.CheckLogout(data).then(response => {
            if (response.data.code == 200) {
                //commit('checkLogin', {})
                delete window.axios.defaults.headers.common["Authorization"];
                return response.data;
            }
            return response.data;
        })
    },

    changePassword({ commit }, data) {
        return Profile.ChangePassword(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    updateSignUp({ commit }, data) {
        return SignUp.checkSignUp(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    updateUserDetails({ commit }, data) {
        return Profile.UpdateProfile(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    sendSupportMessages({ commit }, data) {
        return Login.postSendMessage(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    getUserSupportChat({ commit }, data) {
        return Login.getMessageList(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    customerForgotPasswordData({ commit }, data) {
        return Login.customerForgotPassword(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    forgotPasswordVerifyOTP({ commit }, data) {
        return Login.forgotPasswordVerifyOtp(data).then(response => {
            if (response.data.code == 200) {
                return response.data;
            }
            return response.data;
        })
    },

    getuserWallet({ commit }, data) {
        return Wallet.getUserWalletAmount().then((response) => {
            if (response.data.code == 200) {
                // return response.data;
                commit('getUserWallet', response.data.wallet_amount)
                commit('getUserWalletAmount', response.data.wallet_amount)
                // return response.data.wallet_amount ;
            } else {
                // return response.data;
                commit('getUserWallet', 0)
            }
            return response.data;
        })
    },
    getUserWalletAmount({ commit }, data) {
        return Wallet.gerUserWalletHistory(data).then(response => {
            if (response.data.code == 200) {
                commit('getUserWalletAmount', response.data.wallet_amount)
                return response.data;
            }
            return response.data;
        })
    },




};
const mutations = {
    getUserWallet(state, data) {
        state.userWallet = data;
    },
    getUserWalletAmount(state, data) {
        state.walletAmount = data;
    },
};
const getters = {
    // logout: state => state.logout,
    userWallet: state => state.userWallet,
    walletAmount: state => state.walletAmount,
}

export const user = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};