import Settings from '../apis/setting';

const state = {
    settings: {},
    delivery_zone_settings: {}
};
const actions = {
    getsetting({ commit }, vendor_id) {
        return Settings.settings(vendor_id).then(response => {
            if (response.data.code == 200) {
                commit('getSettings', response.data.settings)
            }
            return response.data;
        })
    },
    getDeliveryZoneSetting({ commit }, data) {
        return Settings.deliveryZoneSettings(data).then(response => {
            if (response.data.code == 200) {
                commit('setDeliveryZoneSetting', response.data.Result)
            }
            return response.data;
        })
    }
};
const mutations = {
    getSettings(state, settings) {
        state.settings = settings
    },
    setDeliveryZoneSetting(state, data) {
        state.delivery_zone_settings = data
    }
};
const getters = {
    settings: state => state.settings,
    getDeliveryZoneSettings: state => state.delivery_zone_settings,
}

export const setting = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};