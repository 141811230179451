export default {
    MenuProductList: (data) => window.axios.post('restaurantcategorymenus', data),
    RestaurantMenusItemDetails: (data) => window.axios.post('restaurant_menus_details', data),
    RestaurantInfo: (data) => window.axios.post('get_restaurant_detail', data),
    restaurantMenusList: (data) => window.axios.post('restaurantmenus', data),
    getRestaurantCategoryWithMenu: (data) => window.axios.post('restaurantCategoryWithMenu', data),
    // productItemDetails: (data) => window.axios.get('item-details/' + data),
    productItemDetails: (data) => window.axios.get('item-details?' + data),
    // productItemDetails: (data, params2) => window.axios.get('item-details/', console.log(data.store_id, data.item_id)),

}