import config from '../config'
export default {
  placeOrder: (data) => window.axios.post('place_order', data),
  viewOrderDetails: (data) => window.axios.post('view_order_details', data),
  getOrderStatus: (data) => window.axios.post('get_order_status', data),
  addOrderRating: (data) => window.axios.post('add_rating', data),
  checkRating: (data) => window.axios.post('check_rating', data),
  reorderDetails: (data) => window.axios.post('reorder_details', data),
  trackOrderCustomer: (data) =>
    window.axios.post('track_of_driver_customer', data),
  uploadOrderImages: (data) => window.axios.post('upload-order-images', data),
  removeOrderImages: (data) => window.axios.post('remove-order-image', data),
  getOrderDistance: (data) => window.axios.post('get-order-distance', data),
  deliveryZone: (data) => window.axios.post('get-vendor-settings', data,),
  // deliveryZone: (data) =>
  //   window.axios.post(
  //     'get-vendor-settings?' +
  //     'vendor_id=' +
  //     data.vendor_id +
  //     '&is_langauge=en' +
  //     '&longitude=' +
  //     +data.lan +
  //     '&latitude=' +
  //     +data.lat + '&order_web=' + 'dev.deonde.co',
  //   ),

  getCurrentOrdersWithratings: (data) =>
    window.axios.post('current_orders_withratings', data),
}
